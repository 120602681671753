import React, { useState, useEffect } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

import styles from './Popup.module.scss';
import page1 from '../../assets/Popup/page1.jpg';
import page2 from '../../assets/Popup/page2.jpg';
import page3 from '../../assets/Popup/page3.jpg';

// 이미지와 함께 사용할 URL 추가 (예시 URL)
const popupArray = [
  { img: page1, url: "" },
  { img: page2, url: "" },
  { img: page3, url: "" },
];

const Popup = () => {
  // 팝업이 열려있는지 여부를 추적하는 상태
  const [isClick, setIsClick] = useState(false);

  // 페이지 로드 시 자동으로 팝업이 열리도록 useEffect 사용
  useEffect(() => {
    // 1.5초 후에 팝업이 열리도록 설정
    const timer = setTimeout(() => {
      setIsClick(true); // 1.5초 후에 팝업 열기
    }, 2000);

    // 컴포넌트가 언마운트될 때 타이머 정리
    return () => clearTimeout(timer);
  }, []); // 빈 배열을 전달하여 컴포넌트 마운트 시 한 번만 실행

  return (
    <div className={styles.container}>
      {isClick && (
        <div className={styles.imgContainer}>
          {popupArray.map((value, idx) => (
            <a key={idx} href={value.url} target="_blank" rel="noopener noreferrer">
              <img
                className={styles.popupImg}
                src={value.img}
                alt={`progio-popup-image-${idx}`}
              />
            </a>
          ))}
        </div>
      )}
      <div className={styles.openPopupBtn} onClick={() => setIsClick(!isClick)}>
        <div className={styles.btnIcon}>
          {isClick ? (
            <FaAngleLeft size={20} color={"#FFFFFF"} />
          ) : (
            <FaAngleRight size={20} color={"#FFFFFF"} />
          )}
        </div>
        <div className={styles.btnText}>POPUP</div>
      </div>
    </div>
  );
};

export default Popup;
